<template>
  <aside class="sidebar-block" :class="{'collapsed': this.collapsed}">
    <div class="sidebar-block__header">
      <div class="sidebar-block__logo">
        <img src="/assets/img/cabinet/logo.svg" alt="">
        <span>VIPAVIASERVICE</span>
      </div>
    </div>
    <div class="sidebar-block__body">
      <div class="sidebar-block__list">
        <ul v-if="this.currentMenu">
          <li
              :key="index"
              v-for="(item, index) in this.currentMenu.items"
          >
            <router-link @click.prevent="processMove(item)" :to="this.generateLink(item)">
              <span class="sidebar-image">
                <span v-html="item.icon"></span>
              </span>
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="sidebar-block__footer">
      <a class="sidebar-block__call" href="tel:+74951205755">
        <span class="sidebar-call__image">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.82667 10.3867C6.74667 14.16 9.84 17.24 13.6133 19.1733L16.5467 16.24C16.9067 15.88 17.44 15.76 17.9067 15.92C19.4 16.4133 21.0133 16.68 22.6667 16.68C23.4 16.68 24 17.28 24 18.0133V22.6667C24 23.4 23.4 24 22.6667 24C10.1467 24 0 13.8533 0 1.33333C0 0.6 0.6 0 1.33333 0H6C6.73333 0 7.33333 0.6 7.33333 1.33333C7.33333 3 7.6 4.6 8.09333 6.09333C8.24 6.56 8.13333 7.08 7.76 7.45333L4.82667 10.3867Z" fill="white"/></svg>
        </span>
        <span class="sidebar-call__aside">
          <span class="sidebar-call__phone">+7 (495) 120-57-55</span>
          <span class="sidebar-call__text">Звонок бесплатный</span>
        </span>
      </a>
      <div class="sidebar-footer__divider"></div>
      <div class="sidebar-footer__list">
        <ul>
          <li>
            <a href="">
									<span class="sidebar-image">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.7778 0H2.22222C1 0 0 1 0 2.22222V17.7778C0 19 1 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V2.22222C20 1 19 0 17.7778 0ZM10.0111 16.6667C9.23333 16.6667 8.61111 16.0444 8.61111 15.2667C8.61111 14.4778 9.23333 13.8778 10.0111 13.8778C10.8 13.8778 11.4 14.4778 11.4 15.2667C11.3889 16.0333 10.8 16.6667 10.0111 16.6667ZM13.3556 8.44445C12.5111 9.67778 11.7111 10.0667 11.2778 10.8556C11.1 11.1778 11.0333 11.3889 11.0333 12.4222H9.01111C9.01111 11.8778 8.92222 10.9889 9.35556 10.2222C9.9 9.25556 10.9333 8.67778 11.5333 7.82222C12.1667 6.92222 11.8111 5.23333 10.0111 5.23333C8.83333 5.23333 8.25556 6.12222 8.01111 6.87778L6.17778 6.1C6.67778 4.61111 8.02222 3.33333 9.98889 3.33333C11.6333 3.33333 12.7556 4.07778 13.3333 5.02222C13.8222 5.82222 14.1111 7.32222 13.3556 8.44445Z" fill="#C9CBDF"/></svg>
									</span>
              Частые вопросы
            </a>
          </li>
          <li>
            <a href="">
									<span class="sidebar-image">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 0H0V20H20V0ZM3.6 17H2V8.44444H3.6C4.04 8.44444 4.4 8.79444 4.4 9.22222V16.2222C4.4 16.65 4.04 17 3.6 17ZM18 10.84C18 11.0578 17.952 11.2678 17.864 11.4622L15.736 16.2456C15.448 16.7122 14.92 17 14.36 17H7.872C6.84 17 6 16.1833 6 15.1878V9.09C6 8.67778 6.168 8.28111 6.472 7.98556L11.6 3L11.968 3.36556C12.256 3.64556 12.488 3.96444 12.672 4.31444C12.752 4.47 12.776 4.65667 12.736 4.82778L12 8.44444H16.4C17.28 8.44444 18 9.14444 18 10V10.84Z" fill="#C9CBDF"/></svg>
									</span>
              Оставить отзыв
            </a>
          </li>
        </ul>
      </div>
    </div>
  </aside>
</template>

<script>
import { useUIStorage } from "@/components/cabinet/stores/uiStore";

export default {
  name: "sidebarComponent",
  methods: {
    generateLink: function (item) {
      let currentLink = {};
      currentLink.name = item.access + '-' + item.link;
      return currentLink;
    },
    processMove: function (item) {
      // We have to process somehow different menus and so
      if (item.triggers) {
        this.uiStore.setSidebarMenuByName(item.triggers);
      }

      if (item.link) {
        this.$router.push(this.generateLink(item));
      }
    }
  },
  mounted() {
    let userMenu = {
      id: 0,
      name: 'user',
      items: [
        {
          'name': 'home',
          'access': 'user',
          'link': 'home',
          'title': 'Главная',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 0L0 6.66667V20H6.25V12.2222H13.75V20H20V6.66667L10 0Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'manager',
          'access': 'manager',
          'link': 'home',
          'title': 'Поиск менеджера',
          'triggers': 'manager',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3647 4.0694L9.99996 0C9.99996 3.42479 9.99996 5.34493 9.99996 8.76972L18.3647 4.0694Z" fill="#C9CBDF"/><path d="M1.63518 4.0694L9.99996 0C9.99996 3.42479 9.99996 5.34493 9.99996 8.76972L1.63518 4.0694Z" fill="#C9CBDF"/><path d="M11.2893 20L11.2579 11.041L20 6.27759V15.8044L11.2893 20Z" fill="#C9CBDF"/><path d="M8.71066 20L8.74211 11.041L-2.67029e-05 6.27759V15.8044L8.71066 20Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'services',
          'access': 'user',
          'link': 'services',
          'title': 'Услуги',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3647 4.0694L9.99996 0C9.99996 3.42479 9.99996 5.34493 9.99996 8.76972L18.3647 4.0694Z" fill="#C9CBDF"/><path d="M1.63518 4.0694L9.99996 0C9.99996 3.42479 9.99996 5.34493 9.99996 8.76972L1.63518 4.0694Z" fill="#C9CBDF"/><path d="M11.2893 20L11.2579 11.041L20 6.27759V15.8044L11.2893 20Z" fill="#C9CBDF"/><path d="M8.71066 20L8.74211 11.041L-2.67029e-05 6.27759V15.8044L8.71066 20Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'prices',
          'access': 'user',
          'link': 'prices',
          'title': 'Цены',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM8.2053 7.47222C8.2053 8.21111 8.70727 8.68556 10.2682 9.14444C11.8291 9.60333 13.5 10.3578 13.5 12.5667C13.5 14.1611 12.4342 15.04 11.0933 15.3278V17H9.03045V15.3122C7.71022 14.9933 6.58251 14.0367 6.5 12.3333H8.01277C8.08841 13.2511 8.64538 13.9667 10.0619 13.9667C11.5815 13.9667 11.9185 13.1111 11.9185 12.5744C11.9185 11.8511 11.5747 11.1667 9.8556 10.7C7.93713 10.1789 6.62377 9.28444 6.62377 7.48778C6.62377 5.98667 7.69646 5.00667 9.03045 4.68V3H11.0933V4.70333C12.5305 5.1 13.2525 6.32889 13.3006 7.66667H11.7809C11.7397 6.69444 11.2859 6.03333 10.0619 6.03333C8.8998 6.03333 8.2053 6.62444 8.2053 7.47222Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'aviation',
          'access': 'user',
          'link': 'aviation',
          'title': 'Бизнес авиация',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 14V12L11.5789 7V1.5C11.5789 0.67 10.8737 0 10 0C9.12632 0 8.42105 0.67 8.42105 1.5V7L0 12V14L8.42105 11.5V17L6.31579 18.5V20L10 19L13.6842 20V18.5L11.5789 17V11.5L20 14Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'orders',
          'access': 'user',
          'link': 'orders',
          'title': 'Заказы',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 16C4.9 16 4.01 16.9 4.01 18C4.01 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM0 0V2H2L5.6 9.59L4.25 12.04C4.09 12.32 4 12.65 4 13C4 14.1 4.9 15 6 15H18V13H6.42C6.28 13 6.17 12.89 6.17 12.75L6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.59 16.3 9.97L19.88 3.48C19.96 3.34 20 3.17 20 3C20 2.45 19.55 2 19 2H4.21L3.27 0H0ZM16 16C14.9 16 14.01 16.9 14.01 18C14.01 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z" fill="white"/></svg>'
        },
        {
          'name': 'blog',
          'access': 'user',
          'link': 'blog',
          'title': 'Блог',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.7778 0H2.22222C1 0 0 1 0 2.22222V17.7778C0 19 1 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V2.22222C20 1 19 0 17.7778 0ZM12.2222 15.5556H4.44444V13.3333H12.2222V15.5556ZM15.5556 11.1111H4.44444V8.88889H15.5556V11.1111ZM15.5556 6.66667H4.44444V4.44444H15.5556V6.66667Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'statistics',
          'access': 'user',
          'link': 'statistics',
          'title': 'Статистика',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 7.77778C8.77778 7.77778 7.77778 8.77778 7.77778 10C7.77778 11.2222 8.77778 12.2222 10 12.2222C11.2222 12.2222 12.2222 11.2222 12.2222 10C12.2222 8.77778 11.2222 7.77778 10 7.77778ZM17.7778 0H2.22222C0.988889 0 0 1 0 2.22222V17.7778C0 19 0.988889 20 2.22222 20H17.7778C19.0111 20 20 19 20 17.7778V2.22222C20 1 19.0111 0 17.7778 0ZM15.8333 10C15.8333 10.2556 15.8111 10.5111 15.7778 10.7556L17.4222 12.0444C17.5667 12.1667 17.6111 12.3778 17.5111 12.5444L15.9556 15.2333C15.8556 15.4 15.6556 15.4667 15.4778 15.4L13.5444 14.6222C13.1444 14.9333 12.7 15.1889 12.2333 15.3889L11.9444 17.4444C11.9111 17.6333 11.7444 17.7778 11.5556 17.7778H8.44445C8.25556 17.7778 8.08889 17.6333 8.05556 17.4556L7.76667 15.4C7.28889 15.2 6.85556 14.9444 6.45556 14.6333L4.52222 15.4111C4.34444 15.4778 4.14444 15.4111 4.04444 15.2444L2.48889 12.5556C2.38889 12.3889 2.43333 12.1778 2.57778 12.0556L4.22222 10.7667C4.18889 10.5111 4.16667 10.2556 4.16667 10C4.16667 9.74444 4.18889 9.48889 4.22222 9.24444L2.57778 7.95556C2.43333 7.83333 2.38889 7.62222 2.48889 7.45556L4.04444 4.76667C4.14444 4.6 4.34444 4.53333 4.52222 4.6L6.45556 5.37778C6.85556 5.06667 7.3 4.81111 7.76667 4.61111L8.05556 2.55556C8.08889 2.36667 8.25556 2.22222 8.44445 2.22222H11.5556C11.7444 2.22222 11.9111 2.36667 11.9444 2.54444L12.2333 4.6C12.7111 4.8 13.1444 5.05556 13.5444 5.36667L15.4778 4.58889C15.6556 4.52222 15.8556 4.58889 15.9556 4.75556L17.5111 7.44444C17.6111 7.61111 17.5667 7.82222 17.4222 7.94444L15.7778 9.23333C15.8111 9.48889 15.8333 9.74444 15.8333 10Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'settings',
          'access': 'user',
          'link': 'settings',
          'title': 'Настройка',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.7778 0H2.22222C1 0 0 1 0 2.22222V17.7778C0 19 1 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V2.22222C20 1 19 0 17.7778 0ZM6.66667 15.5556H4.44444V7.77778H6.66667V15.5556ZM11.1111 15.5556H8.88889V4.44444H11.1111V15.5556ZM15.5556 15.5556H13.3333V11.1111H15.5556V15.5556Z" fill="#C9CBDF"/></svg>'
        }
      ]
    };
    let managerMenu = {
      id: 1,
      name: 'manager',
      items: [
        {
          'name': 'home',
          'access': 'edit',
          'link': 'empty-tab',
          'title': 'Редактор',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 0L0 6.66667V20H6.25V12.2222H13.75V20H20V6.66667L10 0Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'airports',
          'access': 'manager',
          'link': 'airports',
          'title': 'Аэропорты',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 14V12L11.5789 7V1.5C11.5789 0.67 10.8737 0 10 0C9.12632 0 8.42105 0.67 8.42105 1.5V7L0 12V14L8.42105 11.5V17L6.31579 18.5V20L10 19L13.6842 20V18.5L11.5789 17V11.5L20 14Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'providers',
          'access': 'manager',
          'link': 'providers',
          'title': 'Провайдеры',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 0H19C19.4444 0 20 0.444445 20 1V19C20 19.4444 19.4444 20 19 20H1C0.444445 20 0 19.4444 0 19V1C0 0.444445 0.444445 0 1 0ZM3 3H5.8V5.8H3V3ZM8.6 3H17V5.8H8.6V3ZM8.6 8.6H17V11.4H8.6V8.6ZM17 14.2H8.6V17H17V14.2ZM5.8 8.6H3V11.4H5.8V8.6ZM3 14.2H5.8V17H3V14.2Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'currencies',
          'access': 'manager',
          'link': 'currencies',
          'title': 'Валюты',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 0H19C19.4444 0 20 0.444445 20 1V19C20 19.4444 19.4444 20 19 20H1C0.444445 20 0 19.4444 0 19V1C0 0.444445 0.444445 0 1 0ZM3 3H5.8V5.8H3V3ZM8.6 3H17V5.8H8.6V3ZM8.6 8.6H17V11.4H8.6V8.6ZM17 14.2H8.6V17H17V14.2ZM5.8 8.6H3V11.4H5.8V8.6ZM3 14.2H5.8V17H3V14.2Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'validator',
          'access': 'manager',
          'link': 'validator',
          'title': 'Проверка данных',
          'icon': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 0L0 6.66667V20H6.25V12.2222H13.75V20H20V6.66667L10 0Z" fill="#C9CBDF"/></svg>'
        },
        {
          'name': 'back',
          'access': 'user',
          'link': 'home',
          'title': this.$t('buttons.back'),
          'icon': null,
          'triggers': 'user'
        }
      ]
    };

    this.uiStore.setupData();

    this.uiStore.$subscribe((mutation, state) =>{
      this.collapsed = state.sidebarCollapse;
      this.currentMenu = state.sidebarMenus[state.currentSidebarMenu];
    });

    this.uiStore.$patch((state) => {
      state.sidebarMenus = [];
      state.sidebarMenus.push(userMenu);
      state.sidebarMenus.push(managerMenu);
      this.uiStore.setSidebarMenuByName('manager');
    });

    let uiItem = JSON.parse(localStorage.getItem("uiManager"));
    this.collapsed = uiItem.sidebarCollapse;
    this.currentMenu = this.uiStore.sidebarMenus[this.uiStore.currentSidebarMenu];
  },
  data() {
    return {
      collapsed: false,
      currentMenu: {},
      uiStore: useUIStorage()
    }
  }
}
</script>

<style scoped>

</style>